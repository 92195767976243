import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./insurance.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Button, Spinner, Table } from "react-bootstrap";
import axios from "axios";
import useDownloader from 'react-use-downloader';


function Insurance() {
  const { download } = useDownloader();
  const [insuranceData, setInsuranceData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://lunarsenterprises.com:6012/apnastore/list/insurance",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.result) {
            setInsuranceData(data.list);
            setFilteredData(data.list);
          } else {
            console.error("Failed to fetch data:", data.message);
          }
        } else {
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Error while fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleFilter = () => {
    if (fromDate || toDate) {
      const filtered = insuranceData.filter((item) => {
        const itemDate = moment(item.ins_register_date, "YYYY-MM-DD");
        const isAfterFromDate = fromDate ? itemDate.isSameOrAfter(moment(fromDate), "day") : true;
        const isBeforeToDate = toDate ? itemDate.isSameOrBefore(moment(toDate), "day") : true;
        return isAfterFromDate && isBeforeToDate;
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(insuranceData); // Show all data if no date range is selected
    }
  };

  const downloadOrderHistory = async (format) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:6012/apnastore/download/insurance-list",
        { format },
        {
          headers: {
            Authorization: `Bearer YOUR_BEARER_TOKEN_HERE`, // Replace with the actual bearer token
            "Content-Type": "application/json",
          }
        }
      );

      const pdfUrl = response.data.pdf;
      download(pdfUrl, 'insurancelist.pdf');
    } catch (error) {
      console.error("Error downloading order history:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-3 col-md-none">
          <Sidebar />
        </div>
        <div className="col-9">
          <h2 className="text-center">Insurance List</h2>

          {/* Filter Section */}
          <div className="filter-section mb-3">
            <div className="d-flex align-items-center justify-content-end gap-2">
              <DatePicker
                selected={fromDate}
                onChange={(date) => setFromDate(date)}
                placeholderText="From Date"
                dateFormat="dd/MM/yyyy"
                className="form-control"
              />
              <DatePicker
                selected={toDate}
                onChange={(date) => setToDate(date)}
                placeholderText="To Date"
                dateFormat="dd/MM/yyyy"
                className="form-control"
              />
              <button
                className="insurance-filter-button btn btn-primary"
                onClick={handleFilter}
              >
                Filter
              </button>
              <Button
                className="btn-filter"
                onClick={() => downloadOrderHistory("pdf")}
              >
                {isLoading ? (
                  <Spinner
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <FontAwesomeIcon icon={faDownload} />
                )}
              </Button>
            </div>
          </div>

          {filteredData.length > 0 ? (
               <Table responsive bordered className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Registered Date</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Address</th>
                  <th>District</th>
                  <th>Nominee</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr key={item.ins_id}>
                    <td>{index + 1}</td>
                    <td>
                      {moment(item.ins_register_date).format("DD/MM/YYYY")}
                    </td>
                    <td>{item.ins_name}</td>
                    <td>{item.ins_email}</td>
                    <td>{item.ins_mobile}</td>
                    <td>{item.ins_address}</td>
                    <td>{item.ins_district}</td>
                    <td>
                      {item.ins_nominee_name} ({item.ins_nominee_relation})
                    </td>
                    <td>{item.ins_active}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>Loading data or no data available...</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Insurance;
