import React, { useState, useEffect } from "react";
import { Button, Table, Dropdown, DropdownButton } from "react-bootstrap";
import Sidebar from "../Sidebar/Sidebar";
import axios from "axios";
import "./userlist.css";

function Userlist() {
  const [users, setUsers] = useState([]);
  const [levelFilter, setLevelFilter] = useState("");
  const [districtFilter, setDistrictFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const keralaDistricts = [
    "Alappuzha",
    "Ernakulam",
    "Idukki",
    "Kannur",
    "Kasaragod",
    "Kollam",
    "Kottayam",
    "Kozhikode",
    "Malappuram",
    "Palakkad",
    "Pathanamthitta",
    "Thiruvananthapuram",
    "Thrissur",
    "Wayanad",
  ];

  // Fetch users from the API
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          "https://lunarsenterprises.com:6012/apnastore/list-users"
        );
        if (response.data.result) {
          setUsers(
            response.data.list.map((user) => ({
              name: user.u_name,
              phnNumber: user.u_mobile,
              userId: user.u_ref_id,
              level: user.u_position,
              district: user.u_district,
              totalAmount: user.u_total_earning,
            }))
          );
        } else {
          throw new Error(response.data.message || "Failed to fetch data");
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Filtered users based on selected filters
  const filteredUsers = users.filter(
    (user) =>
      (levelFilter
        ? user.level.toLowerCase() === levelFilter.toLowerCase()
        : true) &&
      (districtFilter
        ? user.district.toLowerCase() === districtFilter.toLowerCase()
        : true)
  );

  return (
    <div className="container">
      <div className="row">
        <div className="col-3">
          <Sidebar />
        </div>
        <div className="col-9">
          <h3 className="my-3">User List</h3>

          {/* Filters */}
          <div className="d-flex justify-content-between mb-3">
            <div>
              <select
                className="user-levels-filters-buttons"
                onChange={(e) => setLevelFilter(e.target.value)}
                id="level-filter"
              >
                <option value="">All Levels</option>
                <option value="beginner">Retailer</option>
                <option value="intermediate">Distributor</option>
                <option value="advanced">District Level Distributor</option>
                <option value="retailer">State Level Distributor</option>
                <option value="distributor">National Level Distributor</option>
              </select>
            </div>

            <div>
              <select
                className="user-levels-filters-buttons"
                onChange={(e) => setDistrictFilter(e.target.value)}
                value={districtFilter} // Assume `districtFilter` holds the currently selected value
              >
                <option value="">All Districts</option>
                {keralaDistricts.map((district) => (
                  <option key={district} value={district}>
                    {district}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* User Table */}
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="text-danger">Error: {error}</p>
          ) : (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Phone Number</th>
                  <th>User ID</th>
                  <th>Level</th>
                  <th>District</th>
                  <th>Total Amount Achieved</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user, index) => (
                  <tr key={index}>
                    <td>{user.name}</td>
                    <td>{user.phnNumber}</td>
                    <td>{user.userId}</td>
                    <td>{user.level}</td>
                    <td>{user.district}</td>
                    <td>Rs.{user.totalAmount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </div>
  );
}

export default Userlist;
