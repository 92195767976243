import React, { useState } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import './change.css';
import { useNavigate } from 'react-router';

function Change() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    role: 'user', // Default role as 'user'
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false); // New state for password visibility
  const navigate=useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'password' || name === 'confirmPassword') {
      setPasswordError('');
      setConfirmPasswordError('');
    } else if (name === 'email') {
      setEmailError('');
    }
  };

  const isPasswordStrong = (password) => {
    const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return strongPasswordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);

    // Form validation
    if (!formData.email) {
      setEmailError('Email is required.');
      return;
    }
    if (!formData.password) {
      setPasswordError('Password is required.');
      return;
    }
    if (!isPasswordStrong(formData.password)) {
      setPasswordError(
        'Password must be at least 8 characters long, include uppercase and lowercase letters, a number, and a special character.'
      );
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      setConfirmPasswordError('Passwords do not match.');
      return;
    }

    try {
      // API call
      const response = await axios.post(
        'https://lunarsenterprises.com:6012/apnastore/change-password',
        {
          email: formData.email,
          password: formData.password,
          role: formData.role,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.result) {
        setSuccess(true);
        setFormData({
          email: '',
          password: '',
          confirmPassword: '',
          role: 'user',
        });
        navigate('/');

      } else {
        setError(response.data.message || 'Failed to change password. Please try again.');
      }
    } catch (err) {
      setError('An error occurred. Please check your network and try again.');
    }
  };

  return (
    <div className="profile-page container">
  <h3 className="text-center mb-4">Change Password</h3>
  <Form className="pe-md-4" noValidate onSubmit={handleSubmit}>
    {success && <Alert variant="success">Password changed successfully!</Alert>}
    {error && <Alert variant="danger">{error}</Alert>}

    <Form.Group className="mb-3">
      <Form.Label>Email</Form.Label>
      <Form.Control
        type="email"
        name="email"
        placeholder="Enter email"
        required
        value={formData.email}
        onChange={handleChange}
        isInvalid={!!emailError}
      />
      <Form.Control.Feedback type="invalid">{emailError}</Form.Control.Feedback>
    </Form.Group>

    <Form.Group className="mb-3">
      <Form.Label>Password</Form.Label>
      <Form.Control
        type={showPassword ? 'text' : 'password'}
        name="password"
        placeholder="Enter password"
        required
        value={formData.password}
        onChange={handleChange}
        isInvalid={!!passwordError}
      />
      <Form.Control.Feedback type="invalid">{passwordError}</Form.Control.Feedback>
    </Form.Group>

    <Form.Group className="mb-3">
      <Form.Label>Confirm Password</Form.Label>
      <Form.Control
        type={showPassword ? 'text' : 'password'}
        name="confirmPassword"
        placeholder="Confirm password"
        required
        value={formData.confirmPassword}
        onChange={handleChange}
        isInvalid={!!confirmPasswordError}
      />
      <Form.Control.Feedback type="invalid">{confirmPasswordError}</Form.Control.Feedback>
    </Form.Group>

    <Form.Group className="mb-3">
      <Form.Check
        type="checkbox"
        label="Show Password"
        checked={showPassword}
        onChange={() => setShowPassword(!showPassword)}
      />
    </Form.Group>

    <div className="d-flex justify-content-between">
  <Button href="/" className="cancel-button w-50 me-2">
    Cancel
  </Button>
  <Button type="submit" className="ezy__signin6-btn-submit w-50">
    Save
  </Button>
</div>

  </Form>
</div>

  );
}

export default Change;
