import React, { useState, useEffect } from "react";
import axios from "axios";
import "./dashboard.css";
import Sidebar from "../Sidebar/Sidebar";
import { Table, Button, Form, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import useDownloader from "react-use-downloader";

function Dashboard() {
  const { download } = useDownloader();
  const [orderData, setOrderData] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [searchId, setSearchId] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const keralaDistricts = [
    "Alappuzha",
    "Ernakulam",
    "Idukki",
    "Kannur",
    "Kasaragod",
    "Kollam",
    "Kottayam",
    "Kozhikode",
    "Malappuram",
    "Palakkad",
    "Pathanamthitta",
    "Thiruvananthapuram",
    "Thrissur",
    "Wayanad",
  ];
  useEffect(() => {
    fetchOrders();
  }, [startDate, endDate]);

  const fetchOrders = async () => {
    // setOrderData([]);
    setFilteredOrders([]);
    try {
      console.log(startDate, endDate, "dateeeee");

      const response = await axios.post(
        "https://lunarsenterprises.com:6012/apnastore/list-order",
        { start_date: startDate, end_date: endDate }
      );
      if (response.data.result) {
        const apiData = response.data.list.map((order) => ({
          id: order.od_id,
          username: order.user_name,
          productDetails: order.p_name,
          phoneNumber: order.user_mobile_no,
          deliveryAddress: order.user_address,
          district: order.user_city,
          date: new Date(order.od_created_at).toISOString().split("T")[0],
          paymentmethod: order.od_payment_method,
          paymentstatus: order.od_payment_status,
          orderStatus: order.od_delivery_status,
        }));
        setOrderData(apiData);
        setFilteredOrders(apiData);
        setUserCount(response.data.user_count);
        setOrderCount(response.data.order_count);
        setTotalSales(response.data.total_sales);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const filterOrders = () => {
    let filteredData = orderData;

    if (searchId) {
      filteredData = filteredData.filter((order) =>
        order.id.toString().includes(searchId)
      );
    }

    if (selectedDistrict) {
      filteredData = filteredData.filter(
        (order) => order.district === selectedDistrict
      );
    }

    if (startDate && endDate) {
      fetchOrders();
    }

    setFilteredOrders(filteredData);
  };

  const getButtonStyle = (orderStatus, paymentStatus) => {
    if (orderStatus === "Confirmed" && paymentStatus === "confirmed") {
      return "btn-success";
    } else if (paymentStatus === "pending") {
      return "btn-danger";
    } else {
      return "btn-secondary";
    }
  };

  const downloadOrderHistory = async (format) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://lunarsenterprises.com:6012/apnastore/download/orderhistory",
        { format, first_date: startDate, last_date: endDate }
      );

      const fileUrl = response.data.pdf;
      await download(fileUrl, "orderhistory.pdf");
    } catch (error) {
      console.error("Error downloading order history:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-3 col-md-none">
          <Sidebar />
        </div>
        <div className="col-9">
          <div className="dashboard-first mt-5">
            <div className="column">
              <h4 className="dashboard-details">NUMBER OF USERS</h4>
              <p className="data-value">{userCount}</p>
            </div>
            <div className="column">
              <h4 className="dashboard-details">ORDERS</h4>
              <p className="data-value">{orderCount}</p>
            </div>
            <div className="column">
              <h4 className="dashboard-details">SALES</h4>
              <p className="data-value">{totalSales.toFixed(2)}</p>
            </div>
          </div>

          <div className="row">
            <h4 className="dashboard-order-history-headline mt-5">
              ORDER HISTORY
            </h4>
          </div>
          <div className="row mt-4">
            <div className="col-3">
              <Form.Control
                className="filter-form-input"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="col-3">
              <Form.Control
                className="filter-form-input"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className="col-2">
              <Form.Control
                className="filter-form-input"
                as="select"
                value={selectedDistrict}
                onChange={(e) => setSelectedDistrict(e.target.value)}
              >
                <option value="">Select District</option>
                {keralaDistricts.map((district, idx) => (
                  <option key={idx} value={district}>
                    {district}
                  </option>
                ))}
              </Form.Control>
            </div>
            <div className="col-2">
              <Button className="btn-filter" onClick={filterOrders}>
                Filter
              </Button>
            </div>
            <div className="col-2">
              <Button
                className="btn-filter"
                onClick={() => downloadOrderHistory("pdf")}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <FontAwesomeIcon icon={faDownload} />
                )}
              </Button>
            </div>
          </div>

          <div className="container mt-5">
            <Table responsive bordered>
              <thead>
                <tr>
                  <th>Sl no</th>
                  <th>Username</th>
                  <th>Product Details</th>
                  <th>Delivery Address</th>
                  <th>Phone Number</th>
                  <th>District</th>
                  <th>Date</th>
                  <th>Payment Method</th>
                  <th>Payment Status</th>
                  <th>Order Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredOrders.map((order, index) => (
                  <tr key={order.id}>
                    <td>{index+1}</td>
                    <td>{order.username}</td>
                    <td>{order.productDetails}</td>
                    <td>{order.deliveryAddress}</td>
                    <td>{order.phoneNumber}</td>
                    <td>{order.district}</td>
                    <td>{order.date}</td>
                    <td>{order.paymentmethod}</td>
                    <td>{order.paymentstatus}</td>
                    <td>
                      <Button
                        className={`btn ${getButtonStyle(
                          order.orderStatus,
                          order.paymentstatus
                        )}`}
                      >
                        {order.orderStatus}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {filteredOrders.length === 0 && (
              <div className="no-data-message">
                No orders found for the selected filters.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
