import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, Modal, Form } from "react-bootstrap";
import axios from "axios";

const AddProduct = ({ showModal, setShowModal }) => {
  // const [showModal, setShowModal] = useState(false);
  const [products, setProducts] = useState([]);
  const userToken = localStorage.getItem("user_token");
  const [productData, setProductData] = useState({
    name: "",
    price: "",
    image: null,
    description: "",
    stocks: "",
    quantity: "",
    unit: "",
    subimages: [],
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setProductData((prev) => ({ ...prev, [name]: files[0] }));
    } else if (name === "subimages") {
      setProductData({
        ...productData,
        subimages: Array.from(files), // Convert the FileList to an array
      });
    } else {
      setProductData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleDeleteSubImage = (index) => {
    const updatedsubimages = [...productData.subimages];
    updatedsubimages.splice(index, 1); // Remove the image at the given index
    setProductData({ ...productData, subimages: updatedsubimages });
  };



  const handleAddProduct = () => {
    const formData = new FormData();
    formData.append("name", productData.name);
    formData.append("price", productData.price);
    formData.append("image", productData.image);
    formData.append("description", productData.description);
    formData.append("stocks", productData.stocks);
    formData.append("quantity", productData.quantity);
    formData.append("unit", productData.unit);
    productData.subimages.forEach((file) => {
      formData.append("subimages", file);
    });

    axios
      .post(
        "https://lunarsenterprises.com:6012/apnastore/add-product",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((response) => {
        console.log("Product added successfully:", response.data);
        setShowModal(false);
        setProducts((prev) => [...prev, response.data.newProduct]);
      })
      .catch((error) => {
        console.error("Error adding product:", error);
      });
  };

  return (
    <div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={productData.name}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="text"
                name="price"
                value={productData.price}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Main Image</Form.Label>
              <Form.Control
                type="file"
                name="image"
                onChange={handleInputChange}
              />
              {productData.image && (
                <div className="image-preview mt-2">
                  <img
                    src={URL.createObjectURL(productData.image)}
                    alt="Selected"
                    className="preview-image"
                  />
                </div>
              )}
            </Form.Group>

            {/* Displaying sub-images */}
            <Form.Group>
              <Form.Label>Sub Images</Form.Label>
              <Form.Control
                type="file"
                name="subimages"
                multiple
                onChange={handleInputChange}
              />
              {/* Show existing sub-images with delete option */}
              {productData.subimages.length > 0 && (
                <div className="sub-images-preview mt-2">
                  {productData.subimages.map((image, index) => (
                    <div
                      className="sub-image-container"
                      key={index}
                      style={{ position: "relative" }}
                    >
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Sub Image ${index}`}
                        className="sub-image"
                        style={{ maxWidth: "100px", marginRight: "10px" }}
                      />
                      {/* Delete button */}
                      <button
                        type="button"
                        className="delete-sub-image"
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          background: "rgba(255, 0, 0, 0.5)",
                          border: "none",
                          borderRadius: "50%",
                          color: "white",
                          padding: "5px",
                        }}
                        onClick={() => handleDeleteSubImage(index)}
                      >
                        X
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={productData.description}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Stocks</Form.Label>
              <Form.Control
                type="text"
                name="stocks"
                value={productData.stocks}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="text"
                name="quantity"
                value={productData.quantity}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Unit</Form.Label>
              <Form.Control
                type="text"
                name="unit"
                value={productData.unit}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddProduct}>
            Add Product
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddProduct;
