import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from "./Components/Login/Login"
import Dashboard from './Components/Dashboard/Dashboard';
import Sidebar from './Components/Sidebar/Sidebar';
import Userlist from './Components/Userlist/Userlist';
import Products from './Components/Products/Products';
import Insurance from './Components/Insurance/Insurance';
import ForgetPassword from "./Components/Forgetpassword/Forget";
import Password from "./Components/ChangePassword/Change";
import Banner from './Components/Banner/Banner';


function App() {
  return (
    <BrowserRouter>
     <Routes>
     <Route path="/" element={<Login />} />
     <Route path="/sidebar" element={<Sidebar />} />
     <Route path="/dashboard" element={<Dashboard />} />
     <Route path="/userlist" element={<Userlist />} />
     <Route path="/products" element={<Products />} />
     <Route path="/insurance" element={<Insurance />} />
     <Route path="/forgetpassword" element={<ForgetPassword />} />
     <Route path="/changepassword" element={<Password />} />
     <Route path="/banner" element={<Banner />} />


     </Routes>
     </BrowserRouter>
  );
}

export default App;
