import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Card, Button, Row, Col, Modal, Form } from "react-bootstrap";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./product.css";
import AddProduct from "./AddProduct";

function Products() {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModaledit, setShowModaledit] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const userToken = localStorage.getItem("user_token");

  useEffect(() => {
    Listproduct();
  }, []);

  const Listproduct = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:6012/apnastore/list-products"
      );

      console.log("Fetched products:", response.data.list);

      // Ensure valid products
      const validProducts = response.data.list.filter((product) => product);
      setProducts(validProducts);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleDeleteProduct = async (productId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this product?"
    );
    if (!confirmDelete) return;

    if (!productId) {
      console.error("Product ID is missing or invalid.");
      return;
    }

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:6012/apnastore/delete-product",
        { product_id: productId },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      console.log("Product deleted successfully:", response.data);

      setProducts((prevProducts) =>
        prevProducts.filter((product) => product?.p_id !== productId)
      );
    } catch (error) {
      console.error("Error deleting product:", error);
      alert("Failed to delete product. Please try again.");
    }
  };

  const handleEditProduct = async () => {
    if (!productToEdit) return;

    const formData = new FormData();
    formData.append("p_id", productToEdit.p_id);
    formData.append("name", productToEdit.p_name);
    formData.append("price", productToEdit.p_price);
    formData.append("description", productToEdit.p_description);
    formData.append("stocks", productToEdit.p_stock);
    formData.append("quantity", productToEdit.p_quantity);
    formData.append("unit", productToEdit.p_unit);
    if (productToEdit.p_image) {
      formData.append("image", productToEdit.p_image);
    }

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:6012/apnastore/edit-product",
        formData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Product updated successfully:", response.data);
      setShowModaledit(false);
      Listproduct();
    } catch (error) {
      console.error("Error editing product:", error);
      alert("Failed to edit product. Please try again.");
    }
  };

  return (
    <div className="container">
      <div className="row">
        {/* Sidebar */}
        <div className="col-3">
          <Sidebar />
        </div>

        {/* Main Content */}
        <div className="col-9">
          <h3 className="product-list">PRODUCTS</h3>
          <div className="d-flex justify-content-end">
            <Button
              className="product-list-add-button"
              onClick={() => setShowModal(true)}
            >
              ADD PRODUCT
            </Button>
          </div>

          <AddProduct showModal={showModal} setShowModal={setShowModal} />

          <Row xs={1} sm={2} md={3} className="product-list-cards g-4">
            {products.map((product) => (
              <Col key={product.p_id}>
                <Card className="h-100">
                  <div className="image-container position-relative">
                    <Card.Img
                      variant="top"
                      src={
                        product?.p_image
                          ? `https://lunarsenterprises.com:6012/${product.p_image}`
                          : "https://via.placeholder.com/150"
                      }
                      className="product-image"
                    />
                    <Button
                      className="edit-button position-absolute top-0 start-0 m-2"
                      size="sm"
                      onClick={() => {
                        setProductToEdit(product);
                        setShowModaledit(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </Button>
                    <Button
                      className="delete-buttons position-absolute top-0 end-0 m-2"
                      size="sm"
                      onClick={() => handleDeleteProduct(product?.p_id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </div>
                  <Card.Body>
                    <Card.Title>
                      {product?.p_name || "Unnamed Product"}
                    </Card.Title>
                    <Card.Text>
                      {product?.p_price || "No price available"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>

      {/* Modal for Editing Product */}
      <Modal show={showModaledit} onHide={() => setShowModaledit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={productToEdit?.p_name || ""}
                onChange={(e) => {
                  setProductToEdit({
                    ...productToEdit,
                    p_name: e.target.value,
                  });
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="text"
                value={productToEdit?.p_price || ""}
                onChange={(e) => {
                  setProductToEdit({
                    ...productToEdit,
                    p_price: e.target.value,
                  });
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Main Image</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => {
                  setProductToEdit({
                    ...productToEdit,
                    p_image: e.target.files[0], // Set the selected file as the main image
                  });
                }}
              />
              {productToEdit?.p_image &&
                productToEdit.p_image instanceof File && (
                  <div className="image-preview mt-2">
                    <img
                      src={URL.createObjectURL(productToEdit.p_image)} // Preview the selected main image file
                      alt="Selected Main Image"
                      className="preview-image"
                    />
                  </div>
                )}
              {productToEdit?.p_image &&
                !(productToEdit.p_image instanceof File) && (
                  <div className="image-preview mt-2">
                    <img
                      src={`https://lunarsenterprises.com:6012/${productToEdit.p_image}`} // Use the current image URL for the main image
                      alt="Current Product"
                      className="preview-image"
                    />
                  </div>
                )}
            </Form.Group>

            <Form.Group>
  <Form.Label>Sub Images</Form.Label>
  <Form.Control
    type="file"
    multiple
    onChange={(e) => {
      const files = Array.from(e.target.files); // Convert file list to an array
      setProductToEdit({
        ...productToEdit,
        subimages: files, // Store the selected subimages
      });
    }}
  />
  <div className="sub-images-preview mt-2 d-flex flex-wrap">
    {productToEdit?.subimages && productToEdit.subimages.length > 0 ? (
      productToEdit.subimages.map((file, index) => (
        <div
          key={index}
          className="sub-image position-relative"
          style={{ margin: '10px', position: 'relative' }}
        >
          {file instanceof File ? (
            <img
              src={URL.createObjectURL(file)} // Preview the selected subimage file
              alt={`Subimage ${index + 1}`}
              className="subimage-preview"
              style={{ width: '100px', height: '100px', objectFit: 'cover' }}
            />
          ) : (
            <img
              src={`https://lunarsenterprises.com:6012${file.pi_image}`} // Use the correct property to access the image URL
              alt={`Current Subimage ${index + 1}`}
              className="subimage-preview"
              style={{ width: '100px', height: '100px', objectFit: 'cover' }}
            />
          )}
          {/* Delete Button */}
          <button
            type="button"
            className="btn btn-danger btn-sm position-absolute"
            style={{
              top: '-5px',
              right: '-5px',
              zIndex: 1,
              borderRadius: '50%',
              padding: '2px 5px',
            }}
            onClick={() => {
              const updatedSubimages = [...productToEdit.subimages];
              updatedSubimages.splice(index, 1); // Remove the image at the current index
              setProductToEdit({
                ...productToEdit,
                subimages: updatedSubimages, // Update the subimages array
              });
            }}
          >
            &times;
          </button>
        </div>
      ))
    ) : (
      <p>No subimages available.</p>
    )}
  </div>
</Form.Group>


            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                value={productToEdit?.p_description || ""}
                onChange={(e) => {
                  setProductToEdit({
                    ...productToEdit,
                    p_description: e.target.value,
                  });
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Stocks</Form.Label>
              <Form.Control
                type="text"
                value={productToEdit?.p_stock || ""}
                onChange={(e) => {
                  setProductToEdit({
                    ...productToEdit,
                    p_stock: e.target.value,
                  });
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="text"
                value={productToEdit?.p_quantity || ""}
                onChange={(e) => {
                  setProductToEdit({
                    ...productToEdit,
                    p_quantity: e.target.value,
                  });
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Unit</Form.Label>
              <Form.Control
                type="text"
                value={productToEdit?.p_unit || ""}
                onChange={(e) => {
                  setProductToEdit({
                    ...productToEdit,
                    p_unit: e.target.value,
                  });
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModaledit(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditProduct}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Products;
