import React, { useState } from "react";
import { Nav, Button, Offcanvas } from "react-bootstrap";
import "./sidebar.css";
import kapnalogo from "./../../Assets/kapnalogo.png";

const Sidebar = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const handleClose = () => setShowSidebar(false);
  const handleShow = () => setShowSidebar(true);

  return (
    <div>
      {/* Hamburger Button - Visible Below 1000px */}
      <Button
        className="d-lg-none sidebar-toggle-btn"
        variant="primary"
        onClick={handleShow}
      >
        <i className="fas fa-bars"></i>
      </Button>

      {/* Sidebar for Large Screens */}
      <div className="sidebar d-none d-lg-flex flex-column p-3 bg-light">
        <img
          className="kapna-sidebar-img mb-3"
          src={kapnalogo}
          alt="Kapna Store Logo"
        />
        <Nav className="flex-column">
          <Nav.Link href="/dashboard" className="sidebar-link">
            <i className="fas fa-tachometer-alt me-2"></i> Dashboard
          </Nav.Link>
          <Nav.Link href="/products" className="sidebar-link">
            <i className="fas fa-box me-2"></i> Products
          </Nav.Link>
          <Nav.Link href="/banner" className="sidebar-link">
            <i className="fas fa-sign-out-alt me-2"></i> Banner
          </Nav.Link>
          <Nav.Link href="/userlist" className="sidebar-link">
            <i className="fas fa-users me-2"></i> User List
          </Nav.Link>
          <Nav.Link href="/insurance" className="sidebar-link">
            <i className="fas fa-file-alt me-2"></i> Insurance
          </Nav.Link>
          <Nav.Link href="/" className="sidebar-link text-danger">
            <i className="fas fa-sign-out-alt me-2"></i> Logout
          </Nav.Link>
         
        </Nav>
      </div>

      {/* Offcanvas for Small Screens */}
      <Offcanvas
        show={showSidebar}
        onHide={handleClose}
        placement="start"
        className="sidebar-offcanvas"
      >
        <Offcanvas.Header className="offcanvas-close-button" closeButton>
          <Offcanvas.Title>
            <img
              className="kapna-sidebar-img"
              src={kapnalogo}
              alt="Kapna Store Logo"
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Nav.Link href="/dashboard" className="sidebar-link">
              <i className="fas fa-tachometer-alt me-2"></i> Dashboard
            </Nav.Link>
            <Nav.Link href="/products" className="sidebar-link">
              <i className="fas fa-box me-2"></i> Products
            </Nav.Link>
            <Nav.Link href="/userlist" className="sidebar-link">
              <i className="fas fa-users me-2"></i> User List
            </Nav.Link>
            <Nav.Link href="/insurance" className="sidebar-link">
              <i className="fas fa-file-alt me-2"></i> Insurance
            </Nav.Link>
            <Nav.Link href="/" className="sidebar-link text-danger">
              <i className="fas fa-sign-out-alt me-2"></i> Logout
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Sidebar;
