import React, { useState } from "react";
import "./login.css";
import { Form, Button, Container } from "react-bootstrap";
import kapnalogo from "../../Assets/kapnalogo.png";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const navigate = useNavigate();

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form from reloading the page

    const payload = {
      email, // User email from input
      password, // User password from input
    };

    try {
      // Send POST request to the API
      const response = await fetch(
        "https://lunarsenterprises.com:6012/apnastore/admin/login",
        {
          method: "POST", // POST method
          headers: {
            "Content-Type": "application/json", // Set headers for JSON body
          },
          body: JSON.stringify(payload), // Convert payload to JSON
        }
      );

      const data = await response.json();

      if (data.result === true) {
        toast.success("Login successful!", {
          position: "top-right",
          autoClose: 3000, // Toast will close automatically after 3 seconds
        });
        console.log("Login Response:", data);
        localStorage.setItem("user_token", data.user_token);

        setTimeout(() => {
          navigate("/dashboard");
        }, 3000); // Navigate after 3 seconds
      } else {
        toast.error("Login failed. Please check your credentials.", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (err) {
      toast.error(`An error occurred: ${err.message}`, {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  return (
    <div className="login-page">
      <Container className="login-container">
        <img className="kapnalogo" src={kapnalogo} alt="" />
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="login-label">Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              className="login-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)} // Update email state
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="login-label">Password</Form.Label>
            <div className="password-input-container">
              <Form.Control
                type={showPassword ? "text" : "password"} // Toggle input type
                placeholder="Password"
                className="login-input"
                value={password}
                onChange={(e) => setPassword(e.target.value)} // Update password state
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="eye-icon"
                onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
              />
            </div>
          </Form.Group>

          <div className="text-end mb-3">
            <a href="/forgetpassword" className="forgot-password">
              Forgot Password?
            </a>
          </div>

          <Button type="submit" className="login-button w-100">
            Login
          </Button>
        </Form>
      </Container>
      <ToastContainer />
    </div>
  );
}

export default Login;
