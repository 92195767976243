import React, { useState, useEffect } from 'react';
import './forget.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';


function Forget() {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState(['', '', '', '']);
    const [timer, setTimer] = useState(60);  
    const [otpSent, setOtpSent] = useState(false);
    const [otpExpired, setOtpExpired] = useState(false); 
    const [loading, setLoading] = useState(false); 
    const [errorMessage, setErrorMessage] = useState('');  
    const [emailError, setEmailError] = useState('');

    const [emailUpdate, setEmailUpdate] = useState({
        email: ''
    });
    const navigate=useNavigate();

    const handleEmailChange = (e) => {
        setEmailError(''); 
        const { name, value } = e.target;
        setEmailUpdate((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleOtpChange = (index, value) => {
        if (value.length > 1) return; 
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value && index < otp.length - 1) {
            document.getElementById(`otp-${index + 1}`).focus();
        }
    };

    useEffect(() => {
        let timerInterval;
        if (otpSent && timer > 0) {
            timerInterval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        } else if (timer === 0) {
            setOtpExpired(true);
        }
        return () => clearInterval(timerInterval);
    }, [otpSent, timer]);

    const handleSendOtp = async (e) => {
        e.preventDefault();
        
        if (!emailUpdate.email) {
            setEmailError('* Please enter your email address.');
            return;
        }
        setOtpSent(true);
        setOtpExpired(false);
        setTimer(60);
        setOtp(['', '', '', '']);
        setErrorMessage('');

        try {
            setLoading(true);
            const response = await axios.post('https://lunarsenterprises.com:6012/apnastore/forgot-password', { email: emailUpdate.email, role: 'user' });
            if (response.status === 200) {
                toast.success(response.data.message);
            } else {
                toast.info(response.data.message);
            }
        } catch (error) {
            setErrorMessage('Failed to send OTP.');
        } finally {
            setLoading(false);
        }
    };

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        const otpValue = otp.join('');
        if (otpExpired) {
            setErrorMessage("OTP has expired! Please request a new OTP.");
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post('https://lunarsenterprises.com:6012/apnastore/verify-otp', {
                email: emailUpdate.email,
                OTP: otpValue,
            });
            if (response.status === 200) {
                toast.success("OTP verified successfully!");
                navigate('/changepassword')
            } else {
                setErrorMessage("Invalid OTP. Please try again.");
            }
        } catch (error) {
            setErrorMessage("Failed to verify OTP.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="forget-page">
            <div className="form-container">
                <h2 className="form-title">Reset Your Password</h2>
                <form onSubmit={handleSendOtp} className="forget-form">
                    <div className="form-group">
                        <label className="form-label">Email Address</label>
                        <input
                            className="form-input"
                            type="email"
                            name="email"
                            placeholder="Enter Your Email ID"
                            value={emailUpdate.email}
                            onChange={handleEmailChange}
                            required
                        />
                        {emailError && <p className="error-message">{emailError}</p>}
                    </div>
                    <div className="otp-send">
                        <button 
                            className="send-otp-button"
                            type="button"
                            onClick={handleSendOtp}
                            disabled={otpSent && timer > 0}
                        >
                            {otpSent ? `Resend OTP in ${timer}s` : 'Send OTP'}
                        </button>
                    </div>
                </form>
                <form onSubmit={handleEmailSubmit} className="forget-form">  
                    <div className="form-group">
                        <label className="form-label">Enter OTP</label>
                        <div className="otp-container">
                            {otp.map((value, index) => (
                                <input
                                    key={index}
                                    id={`otp-${index}`}
                                    type="number"
                                    maxLength="1"
                                    value={value}
                                    onChange={(e) => handleOtpChange(index, e.target.value)}
                                    required
                                    className="otp-input"
                                />
                            ))}
                        </div>
                    </div>
                    <button 
                        type="submit" 
                        className="submit-btn"
                        disabled={otpExpired || loading}
                    >
                        {loading ? 'Verifying...' : 'Verify OTP'}
                    </button>
                </form>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>
            <ToastContainer />
        </div>  
    );
}

export default Forget;
