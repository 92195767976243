import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import "./banner.css";
import Sidebar from "../Sidebar/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

function Banner() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [banners, setBanners] = useState([]);
  const BEARER_TOKEN = localStorage.getItem("user_token");

  // Fetch banners on component load
  useEffect(() => {
    fetchBanner();
  }, []);

  // Fetch banners from API
  const fetchBanner = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:6012/apnastore/list-banner"
      );
      setBanners(response.data.list);
    } catch (error) {
      console.error("Error fetching banners:", error);
    }
  };

  // Handle file input change
  const handleFileChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  // Handle adding a new banner
  const addBanner = async () => {
    if (!selectedImage) {
      alert("Please select an image to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedImage);

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:6012/apnastore/add-banner",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${BEARER_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        fetchBanner(); // Refresh banners after adding
        setSelectedImage(null); // Clear selected image
        alert("Banner added successfully.");
      } else {
        console.error("Failed to upload the image.");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  // Handle deleting a banner
  const deleteBanner = async (b_id) => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:6012/apnastore/delete-banner",
        { banner_id: b_id },
        {
          headers: {
            Authorization: `Bearer ${BEARER_TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        alert("Banner deleted successfully.");
        fetchBanner();
      } else {
        console.error("Failed to delete the banner:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting banner:", error);
      alert("An error occurred while deleting the banner.");
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-3 col-md-none">
          <Sidebar />
        </div>
        <div className="col-9 mt-5">
          <div className="carousel-container">
          {banners ? ( 
              <Carousel>
                {banners.map((banner) => (
                  <Carousel.Item key={banner.b_id}>
                    <img
                      className="d-block w-100"
                      src={`https://lunarsenterprises.com:6012/${banner.b_image}`}
                      alt={`Banner ${banner.b_id}`}
                    />
                    <button
                      className="delete-button"
                      onClick={() => deleteBanner(banner.b_id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </Carousel.Item>
                ))}
              </Carousel>
            ) : (
              <div className="no-banner-message">
                <p className="no-banner-available">No banner available</p>
              </div>
            )}
            <div className="banner-input mt-5">
              <input type="file" accept="image/*" onChange={handleFileChange} />
              <button className="addbanner-submit" onClick={addBanner}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
